import React from 'react'

import { Button } from '@mui/material'
import { useCookies } from 'react-cookie'
import { logEventButtonClicked, logSMEvent } from '../../events/logEvent'
import '../../App.css'
import { neutral80White, primaryPrimary } from '../../style'
import { SMCookies } from '../../types/main'
import { normalizeDaysToText } from '../../helpers/others'
import { showTwoDecimals } from '../../helpers/location'

const ButtonTracked = function ({ onClick, reference, ...props }: any) {
  const smCookies = useCookies<string>(['savemoney'])[0]
  const cookies = smCookies as SMCookies
  const handleClick = (event: any) => {
    logEventButtonClicked(cookies.user, props.buttonname, cookies)
    if (onClick) {
      onClick(event)
    }
  }

  return <Button ref={reference} onClick={handleClick} {...props} />
}

export function PrimaryButton({
  onClick,
  buttonname,
  children,
  sx,
  disabled = false,
}: {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  buttonname: string
  children: React.ReactNode
  sx?: React.CSSProperties
  disabled?: boolean
}) {
  return (
    <ButtonTracked
      buttonname={buttonname}
      onClick={onClick}
      variant="contained"
      disableRipple={false} // enable wave effect
      disabled={disabled}
      style={{
        display: 'flex',
        minWidth: '288px',
        maxWidth: '384px',
        width: '100%',
        flex: '1',
        minHeight: '55px',
        maxHeight: '55px',
        borderRadius: 40,
        justifyContent: 'center',
        alignItems: 'center',
        textTransform: 'none',
        backgroundColor: primaryPrimary,
        fontSize: '14px',
        fontWeight: 'bold',
        ...sx,
      }}
    >
      {children}
    </ButtonTracked>
  )
}

export function DiscountMapButton({
  onClick,
  url,
  buttonname,
  children,
  sx,
  reference,
}: {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  url: string
  buttonname: string
  children: React.ReactNode
  sx?: React.CSSProperties
  reference?: React.RefObject<HTMLButtonElement>
}) {
  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      style={{
        textDecoration: 'none',
        display: 'flex',
      }}
    >
      <ButtonTracked
        buttonname={buttonname}
        reference={reference}
        onClick={onClick}
        variant="outlined"
        disableRipple={false} // enable wave effect
        style={{
          display: 'flex',
          minWidth: 'fit-content',
          borderRadius: 40,
          maxHeight: 34,
          textTransform: 'none',
          borderColor: neutral80White,
          color: primaryPrimary,
          // backgroundColor: primaryPrimary,
          ...sx,
        }}
      >
        {children}
      </ButtonTracked>
    </a>
  )
}

export function ShareDiscountButton({
  discount,
  buttonname,
  children,
  sx,
}: {
  discount: any
  buttonname: string
  children: React.ReactNode
  sx?: React.CSSProperties
}) {
  const handleClick = async (e: any) => {
    e.stopPropagation()
    const shareId = Math.random().toString(36).substring(2)
    const discountUrl = `www.savemoney.cl/descuentos?openDiscountId=${discount.id}&shareId=${shareId}`
    const title = discount.titulo || ''
    const tarjetaText = discount.subTarjeta
      ? `la(s) tarjeta(s): ${discount.subTarjeta} del ${discount.club}`
      : `${discount.club}`

    // Add valid days
    const validDays = normalizeDaysToText(discount.diasNormalizados)
    const validDaysText = validDays === 'Todos' ? 'Todos los días' : `Válido: ${validDays}`

    // Add expiration date if available
    const expirationText = discount.fecha_hasta ? `\n📅 Hasta: ${discount.fecha_hasta}` : ''

    // Add location if available
    const locationText = discount.XClosestLocation?.distance
      ? `\n📍 A ${showTwoDecimals(discount.XClosestLocation.distance)} km de mi`
      : ''

    const text = `
Encontré este descuento usando SaveMoney. 

  *${title}*
💳 Haciendo uso de ${tarjetaText}
⏰ ${validDaysText}${expirationText}${locationText}
Antes de dirigirte al local, asegurate de leer bien la descripción del descuento en el siguiente enlace:
👉 ${discountUrl}
`

    logSMEvent('DISCOUNT_DRAWER_SHARE_BUTTON_CLICKED', {
      id: discount.id,
      url: discountUrl,
      shareId,
      buttonname,
    })
    // Lo dejo comentado por que en mi pc no funciona bien y es más fácil whatsapp
    // if (navigator.share) {
    //   try {
    //     await navigator.share({
    //       title,
    //       text,
    //     })
    //   } catch (error: any) {
    //     logSMEvent('ERROR', {
    //       id: 'discount_share_error',
    //       buttonname,
    //       message: 'Error al compartir el descuento',
    //       error: error.toString(),
    //       discountId: discount.id,
    //     })
    //   }
    // } else {
    // Fallback for browsers that do not support the Web Share API
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(text)}`
    window.open(whatsappUrl, '_blank')
    // }
  }
  return (
    <PrimaryButton onClick={handleClick} buttonname={buttonname} sx={sx}>
      {children}
    </PrimaryButton>
  )
}

export function SMShareButton({
  // more generic
  title,
  text,
  onClick,
  buttonName,
  children,
  sx,
}: {
  title: string
  text: string
  onClick: () => void
  buttonName: string
  children: React.ReactNode
  sx?: React.CSSProperties
}) {
  const handleClick = async (e: any) => {
    e.stopPropagation()
    onClick()
    if (navigator.share) {
      try {
        await navigator.share({
          title,
          text,
        })
      } catch (error: any) {
        logSMEvent('ERROR', {
          id: `SHARE_BUTTON_${buttonName}`,
          error: error.toString(),
        })
      }
    } else {
      // Fallback for browsers that do not support the Web Share API
      const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(text)}`
      window.open(whatsappUrl, '_blank')
    }
  }
  return (
    <PrimaryButton onClick={handleClick} buttonname={buttonName} sx={sx}>
      {children}
    </PrimaryButton>
  )
}
