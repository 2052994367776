import React, { useContext, useEffect, useState } from 'react'
import { Discount } from '../../../../types/discount'
import { DiscountContext } from '../../../../contexts/DiscountContext'
import { primary40White } from '../../../../style'
import { LIST_DISCOUNT_CARD_STYLE } from '../../../../consts/stylesConsts'
import { logSMEvent } from '../../../../events/logEvent'
import useObserver from '../../../../hooks/useObserver'
import { SMHorizontalDiv } from '../../../../SMComponents/SMView'
import { DiscountActionFooter } from '../DiscountActionFooter'
import discountCardStyle from '../discountCard.module.css'

const { backgroundImage, discountHeaderFrame } = discountCardStyle

export const PromotedDiscount = ({ discount }: { discount: Discount }) => {
  const { handleDiscountClick } = useContext(DiscountContext)
  const [wasObserved, setWasObserved] = useState(false) // is or was observed
  const handleObservation = () => {
    // con esto nos aseguramos de que se ejecute una sola vez el log de observado
    setWasObserved(true)
  }

  useEffect(() => {
    if (wasObserved) {
      const discountSummaryToLog = {
        id: discount.id,
        club: discount.club,
      }
      logSMEvent('PROMOTED_DISCOUNT_IMPRESSION', { ...discountSummaryToLog })
    }
  }, [wasObserved])

  const componentRef = useObserver(handleObservation)

  const imageHeight = `calc(${LIST_DISCOUNT_CARD_STYLE.height} - 46px)`

  return (
    <div
      // className={discountCard}
      key={discount.id}
      ref={componentRef}
      onClick={() => {
        logSMEvent('PROMOTED_DISCOUNT_CLICKED', {
          id: discount.id,
          mainTagV3: discount.mainTagV3,
          club: discount.club,
        })
        handleDiscountClick(discount)
      }}
      style={{
        boxShadow: `0px 1px 10px 4px  ${primary40White}`,
        ...LIST_DISCOUNT_CARD_STYLE,
        flexDirection: 'column',
      }}
    >
      <div className={discountHeaderFrame} style={{ height: imageHeight }}>
        <img // background para que se vea lindo atras de la imagen
          className={backgroundImage}
          src={discount.promotedImage}
          alt="."
          referrerPolicy="no-referrer"
          style={{
            width: '100%',
            minHeight: imageHeight,
          }}
        />
        <img
          style={{
            width: '100%',
            objectFit: 'contain',
            minHeight: imageHeight,
          }}
          src={discount.promotedImage}
          alt="."
          referrerPolicy="no-referrer"
        />
      </div>

      {discount?.CTA ? (
        <SMHorizontalDiv sx={{ margin: '0px 10px 0px 10px' }}>
          <DiscountActionFooter discount={discount} category={'SaveMoney'} />
        </SMHorizontalDiv>
      ) : null}
    </div>
  )
}
