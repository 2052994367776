import React, { useContext, useEffect, useState } from 'react'
// import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
// import { Autoplay, Pagination, Navigation } from 'swiper/modules'
import { SMP } from '../../../SMComponents/SMText'
import { addUtmParamsToUrl } from '../../../helpers/others'
import { logSMEvent } from '../../../events/logEvent'
import { Ad } from '../../../types/ads'
import { AppContext } from '../../../contexts/AppContext'
import { ADS_FRONTEND_VERSION } from '../../../consts/version'
import { DiscountContext } from '../../../contexts/DiscountContext'
import { CircularProgress } from '@mui/material'
import { SMVerticalDiv } from '../../../SMComponents/SMView'
import { useViewportWidth } from '../../../hooks/useViewportSize'
import { request } from '../../../helpers/request'
import { filterBannersToShow } from './ads/helpers'
import useObserver from '../../../hooks/useObserver'

export const Banner = ({
  ad,
  isActive,
  index,
  imageUrl,
}: {
  ad: Ad
  isActive: boolean
  index: number
  imageUrl?: string
}) => {
  const { displayedAds, addDispleyedAds, user, auth, amountOfVisits } = useContext(AppContext)
  const { handleOpenAdDrawer } = useContext(DiscountContext)
  const displayedAdsCounterLocalStorage = localStorage.getItem('displayedAdsCounterJSON')
  const displayedAdsCounterJSON = displayedAdsCounterLocalStorage
    ? JSON.parse(displayedAdsCounterLocalStorage)
    : {}
  const [wasObserved, setWasObserved] = useState(false) // is or was observed

  const handleObservation = () => {
    setWasObserved(true)
  }

  const componentRef = useObserver(handleObservation)

  const authValues = {
    auth_id: auth ? auth.id : null,
    auth_created_at: auth ? auth.createdAt : null,
    auth_liked_discounts_amount: auth ? auth.likedDiscounts?.length : null,
    auth_saved_discounts_amount: auth ? auth.savedDiscounts?.length : null,
    auth_phone_number: auth ? auth.phoneNumber : null,
    auth_email: auth ? auth.email : null,
    auth_role: auth ? auth.role : null,
  }

  const logImpressionParams = {
    ad_id: ad.id,
    ads_frontend_version: ADS_FRONTEND_VERSION,
    tager_version_b: ad.targetVersionBackend,
    user_frontend_id: user,
    amount_of_visits: amountOfVisits,
    ...authValues,
    ad_index_in_swiper: index,
    times_ad_displayed_to_user: displayedAdsCounterJSON[ad.id] || 0,
  }
  // console.log(logImpressionParams)

  useEffect(() => {
    if (isActive && imageUrl && !displayedAds.has(ad.id) && wasObserved) {
      logSMEvent('AD_IMPRESSION', logImpressionParams)
      request('ad/impression', {
        method: 'POST',
        body: JSON.stringify({
          adId: ad.id,
        }),
      })
      addDispleyedAds(ad.id)
      // add display into localStorage to keep track of how many times the ad was displayed to the user
      const adCounterDisplayed = (displayedAdsCounterJSON[ad.id] || 0) + 1
      displayedAdsCounterJSON[ad.id] = adCounterDisplayed
      localStorage.setItem('displayedAdsCounterJSON', JSON.stringify(displayedAdsCounterJSON))
    }
  }, [isActive, imageUrl, ad.id, wasObserved]) // puede cambiar el ad.id manteniendo la primera posicion, por consecuencia de un cambio en los filtros

  let adUrl = ad.url
  if (ad.url && !ad.url.toLowerCase().includes('mailto') && !ad.url.includes('wa.me')) {
    adUrl = addUtmParamsToUrl(ad)
  }
  if (!imageUrl) return null
  return (
    <a
      rel="noreferrer"
      ref={componentRef}
      target="_blank"
      href={ad.onClick === 'OPEN_URL' ? adUrl : undefined} // ojo que hacer un redirect en onclick no funciona en la app
      onClick={() => {
        logSMEvent('CLICK_AD', logImpressionParams)
        if (ad.onClick === 'OPEN_LOTTERY_DRAWER') {
          handleOpenAdDrawer({ ad, logImpressionParams })
        }
        if (ad.onClick === 'OPEN_URL') {
          // ojo que hacer un window.open no funciona en la app
          request('ad/convertion', {
            method: 'POST',
            body: JSON.stringify({
              adId: ad.id,
            }),
          })
        }
      }}
      style={{
        cursor: 'pointer',
        display: 'flex',
        width: '100%',
      }}
    >
      <img
        src={imageUrl}
        alt="-"
        style={{
          // expand to fill the container
          objectFit: 'cover',
          width: '100%',
        }}
      />
    </a>
  )
}

export const BannerSwiper = ({ ads }: { ads: Ad[] }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  const isMobile = useViewportWidth()[1]

  const handleSlideChange = (swiper: any) => {
    const currentIndex = swiper.activeIndex
    setActiveIndex(currentIndex)
    const ad = ads[currentIndex]
    logSMEvent('SWIPER_SLIDE_CHANGED', { showing_ad_id: ad.id })
  }

  return (
    <div
      style={{
        overflow: 'hidden',
        borderRadius: '20px',
        // desktop es 1500x250
        // mobile es 400x300
        width: '80%',
        maxWidth: isMobile ? '320px' : '740px',
        maxHeight: isMobile ? '240px' : '150px',
        boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.75)',
      }}
    >
      <Swiper
        pagination={true}
        onSlideChange={handleSlideChange}
        modules={
          [
            //Autoplay,
            // Pagination,
            // Navigation,
          ]
        }
        className="mySwiper"
        // spaceBetween={50}
        slidesPerView={1}
        // autoplay={{
        //   delay: 3800,
        //   disableOnInteraction: true,
        // }}
      >
        {ads.map((ad, index) => {
          return (
            <SwiperSlide key={index}>
              <Banner
                ad={ad}
                isActive={index == activeIndex}
                index={index}
                imageUrl={isMobile ? ad.images?.mobile : ad.images?.desktop}
              />
            </SwiperSlide>
          )
        })}
      </Swiper>
    </div>
  )
}

const MIN_AMOUNT_OF_VISITS_TO_SEE_BANNER = 4

export const HomeBannerFrameInDiscountList = () => {
  const { ads, filtersSelected, amountOfVisits } = useContext(AppContext)
  if (amountOfVisits < MIN_AMOUNT_OF_VISITS_TO_SEE_BANNER) return null
  if (ads === null) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
        <SMVerticalDiv>
          <SMP>Cargando...</SMP>
          <CircularProgress style={{ margin: 'auto' }} />
        </SMVerticalDiv>
      </div>
    )
  }
  const bannersToShow = filterBannersToShow(ads, filtersSelected)

  if (!bannersToShow.length) return null // en caso de que ninguno haga match
  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
      <BannerSwiper ads={bannersToShow} />
    </div>
  )
}
