// Este comentario me imagino que es un error xq si esta en las dependencias
import React, { useContext, useEffect, useState } from 'react'
import { Button } from '@mui/material'
import ExpandMoreRounded from '@mui/icons-material/ExpandMoreRounded'

import styles from './discountsHolder.module.css'
import DiscountCard from './DiscountCard'
import { Discount } from '../../../types/discount'
import { neutralWhite } from '../../../style'
import { logSMEvent } from '../../../events/logEvent'
import { MAINTAGV3Enum } from '../../../types/mainTagV3'
import { filterAdsToShowInList } from './ads/helpers'
import { Ad } from '../../../types/ads'
import { AppContext } from '../../../contexts/AppContext'
import { DiscountsPromotedOrBanner } from './ads/DiscountsPromotedOrBanner'
// import { AppContext } from '../../../contexts/AppContext'
// import { filterAdsToShowInList } from './ads/helpers'
// import { Ad } from '../../../types/ads'
// import { useFilterOptions } from '../../../hooks/filterOptions'
// import { DiscountsPromotedOrBanner } from './ads/DiscountsPromotedOrBanner'

const { discountAndDescriptionFrame, discountsHolder, finishDot } = styles

const AMOUNT_DISCOUNTS_BY_CUPON = 3

const DISCOUNTS_TO_RENDER_EACH_LOADING = 12 // (3x4) so it can display nice in any screen

const DiscountsHolder = function ({
  discountsToShow,
  category,
  isStar,
}: {
  discountsToShow: Discount[]
  category: MAINTAGV3Enum
  isStar: boolean
}) {
  const { ads, filtersSelected } = useContext(AppContext)
  const [amountDiscountsToRender, setAmountDiscountsToRender] = useState(
    DISCOUNTS_TO_RENDER_EACH_LOADING
  )
  const [bannersToShow, setBannersToShow] = useState<Ad[]>([])

  useEffect(() => {
    const bannersToShow = filterAdsToShowInList(ads, category, filtersSelected)
    setBannersToShow(bannersToShow)
  }, [ads, filtersSelected])

  // const [promotedDiscounts, setPromotedDiscounts] = useState<Discount[]>([])

  // useEffect(() => {
  //   setPromotedDiscounts(discountsToShow.filter((discount) => discount.promoted))
  // }, [discountsToShow])

  const discountsWithoutCoupon = discountsToShow.filter((x) => x.club !== 'SaveMoney')
  const discountsWithCoupon = discountsToShow.filter((x) => x.club === 'SaveMoney')
  const discountListWithCouponEvery3: Discount[] = []
  let couponIndex = 0
  let regularIndex = 0

  while (regularIndex < discountsWithoutCoupon.length || couponIndex < discountsWithCoupon.length) {
    // Add 3 regular discounts (or whatever is left)
    for (
      let i = 0;
      i < AMOUNT_DISCOUNTS_BY_CUPON && regularIndex < discountsWithoutCoupon.length;
      i++
    ) {
      discountListWithCouponEvery3.push(discountsWithoutCoupon[regularIndex])
      regularIndex++
    }

    // Add a coupon if available
    if (couponIndex < discountsWithCoupon.length) {
      discountListWithCouponEvery3.push(discountsWithCoupon[couponIndex])
      couponIndex++
    }
  }

  return (
    <div className={discountAndDescriptionFrame}>
      <div className={discountsHolder}>
        {discountListWithCouponEvery3?.slice(0, amountDiscountsToRender).map((discount, index) => {
          return (
            <React.Fragment key={discount.id}>
              <DiscountCard discount={discount} category={category} isStar={isStar} />
              <DiscountsPromotedOrBanner
                bannersToShow={bannersToShow}
                index={index}
                // promotedDiscounts={promotedDiscounts}
                promotedDiscounts={[]}
              />
            </React.Fragment>
          )
        })}
      </div>
      {amountDiscountsToRender >= discountsToShow?.length ? (
        <div className={finishDot} />
      ) : (
        <Button
          onClick={() => {
            logSMEvent('VER_MAS_DESCUENTOS', {
              category,
            })
            setAmountDiscountsToRender(amountDiscountsToRender + DISCOUNTS_TO_RENDER_EACH_LOADING)
          }}
          sx={{
            borderRadius: 40,
            padding: '4px 16px',
            margin: '20px 0',
            display: 'flex',
            backgroundColor: neutralWhite,
            flex: 1,
            boxShadow: '2px 2px 4px rgba(70,70,70,0.25)',
            alignItems: 'center',
            textTransform: 'none',
          }}
        >
          Ver más
          <ExpandMoreRounded />
        </Button>
      )}
    </div>
  )
}

export default DiscountsHolder
