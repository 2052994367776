import React, { useContext } from 'react'
import { Discount } from '../../../types/discount'
import { neutral50White, neutral80White, neutralWhite } from '../../../style'
import { SMP } from '../../../SMComponents/SMText'
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded'
import { SMHorizontalDiv, SMVerticalDiv } from '../../../SMComponents/SMView'
import CalendarTodayRoundedIcon from '@mui/icons-material/CalendarTodayRounded'
import { normalizeDaysToText } from '../../../helpers/others'
import { DiscountContext } from '../../../contexts/DiscountContext'

const CHILD_LOGO_SIZE = 50

export const DiscountChildrenFrame = ({
  childrenDiscounts,
}: {
  childrenDiscounts: Discount[] | null
}) => {
  const { handleDiscountClick } = useContext(DiscountContext)
  if (!childrenDiscounts || childrenDiscounts.length === 0) {
    return null
  }
  return (
    <SMVerticalDiv sx={{ marginBottom: 40 }}>
      <SMP>Descuentos relacionados:</SMP>
      <div style={{ display: 'flex', gap: 20, flexWrap: 'wrap' }}>
        {childrenDiscounts.map((childDiscount, index) => {
          return (
            <div
              key={index}
              onClick={() => handleDiscountClick(childDiscount)}
              style={{
                border: '1px solid red',
                borderColor: neutral80White,
                padding: 20,
                borderRadius: 20,
                minWidth: '160px',
                maxWidth: '160px',
                cursor: 'pointer',
                boxShadow: '0px 0px 10px 0px rgba(0,0,0,0.1)',
              }}
            >
              <SMHorizontalDiv maxSpaceBetween sx={{ marginBottom: 20 }}>
                <SMP black bold>
                  {childDiscount.local}
                </SMP>
                <div
                  style={{
                    border: '1px solid',
                    borderRadius: '10px',
                    borderColor: neutral80White,
                    backgroundColor: neutralWhite,
                    width: CHILD_LOGO_SIZE,
                    height: CHILD_LOGO_SIZE,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <img
                    style={{
                      width: CHILD_LOGO_SIZE,
                      height: CHILD_LOGO_SIZE,
                      objectFit: 'cover',
                    }}
                    alt="."
                    src={childDiscount.logo}
                  />
                </div>
              </SMHorizontalDiv>
              {childDiscount.parentId && (
                <>
                  <SMHorizontalDiv>
                    <CalendarTodayRoundedIcon fontSize="small" htmlColor={neutral50White} />
                    <SMP>{normalizeDaysToText(childDiscount.diasNormalizados)}</SMP>
                  </SMHorizontalDiv>

                  {childDiscount.direcciones.split(';').map((d) => {
                    return (
                      <SMHorizontalDiv key={d}>
                        <LocationOnRoundedIcon
                          fontSize="small"
                          htmlColor={neutral50White}
                          sx={{ marginBottom: 0 }}
                        />
                        <SMP>{d}</SMP>
                      </SMHorizontalDiv>
                    )
                  })}
                </>
              )}
            </div>
          )
        })}
      </div>
    </SMVerticalDiv>
  )
}
